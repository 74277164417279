// Libs
import React from 'react';
// Utils
import { filterDataByFilterOptions } from 'utils/filters';
import { ImportUtils } from 'utils/Orders/ImportProcess';
// Hooks
import { useOrderApi } from 'hooks';
// Components
import { Drawer } from 'components';
import HeaderPreview from './HeaderPreview';
import TablePreview from './TablePreview';
// import ListPreview from "./ListPreview";

const ImportPreview = ({
	isMobile,
	orders,
	filters,
	dateFormat,
	templateValidator,
	onSetState,
	onResetOrders,
	sendToast,
}) => {
	const { getCreationAssignment, create: createOrders } = useOrderApi();

	const previewOrders = filterDataByFilterOptions(orders, filters);
	const templateValidatorFields =
		ImportUtils.getTemplateValidatorFields(templateValidator);

	const {
		cityIdField,
		townshipIdField,
		contractZoneIdField,
		orderZoneIdField,
		assignedTechIdField,
	} = templateValidatorFields;
	const zoneIdField = orderZoneIdField || contractZoneIdField;

	const onCreateOrders = async setIsFetching => {
		setIsFetching(true);

		const assignedOrders = await getCreationAssignment(orders, {
			dateFormat,
			validationTemplateId: templateValidator.id,
		});

		createOrders({
			orders: assignedOrders,
			dateFormat,
			validationTemplateId: templateValidator.id,
		})
			.then(() => {
				setIsFetching(false);
				onResetOrders();
				sendToast({
					message: 'Ordenes importadas!',
					type: 'success',
				});
			})
			.catch(err => {
				sendToast({
					message: err.message,
					type: 'error',
				});
				onSetState({
					orders: err.orders,
				});
				setIsFetching(false);
			});
	};

	const onStandardChange = (selected, callback) => {
		if (selected.items.length) selected.items.forEach(callback);
		else callback();

		onSetState({ orders: previewOrders });
	};

	const onChangeZoneHandler = ({ option, rowData, selected }) => {
		const changeOrdersValue = (orderId = rowData.id) => {
			const idx = previewOrders.findIndex(order => order.id === orderId);
			if (idx !== -1) {
				previewOrders[idx][zoneIdField.id] = option.id;
				previewOrders[idx][zoneIdField.name] = option.name;
			}
		};

		onStandardChange(selected, changeOrdersValue);
	};

	const onChangeExpertHandler = ({ option, rowData, selected }) => {
		const changeOrdersValue = (orderId = rowData.id) => {
			const idx = previewOrders.findIndex(order => order.id === orderId);
			if (idx !== -1) {
				previewOrders[idx][assignedTechIdField.id] = option.id;
				previewOrders[idx][assignedTechIdField.name] = option.name;
				previewOrders[idx].entityId = option.entityId;
				previewOrders[idx].entityName = option.entityName;
			}
		};
		onStandardChange(selected, changeOrdersValue);
	};

	const onFieldTypeChange = (value, { rowData, field }) => {
		const idx = previewOrders.findIndex(order => order.id === rowData.id);
		const order = previewOrders[idx];
		if (order) {
			order[field.id] = value;
			previewOrders[idx] = order;
		}
		onSetState({ orders: previewOrders });
	};

	const fieldActions = {
		ids: {},
		types: {
			string: {
				onChange: onFieldTypeChange,
			},
			int: {
				onChange: onFieldTypeChange,
			},
			decimal: {
				onChange: onFieldTypeChange,
			},
			date: {
				onChange: onFieldTypeChange,
			},
			datetime: {
				onChange: onFieldTypeChange,
			},
			time: {
				onChange: onFieldTypeChange,
			},
			boolean: {
				onChange: onFieldTypeChange,
			},
		},
	};

	if (assignedTechIdField)
		fieldActions.ids[assignedTechIdField.id] = {
			onChange: onChangeExpertHandler,
		};
	if (cityIdField && townshipIdField && zoneIdField) {
		fieldActions.ids[zoneIdField.id] = {
			onChange: onChangeZoneHandler,
		};
	}

	return (
		<Drawer
			key='importPreviewDrawer'
			className='animated fadeIn fastest'
			height='100%'
			width='100%'
			// $maxWidth="400px"
			placement='top'
			closable={false}
			visible={true}
			title={
				<HeaderPreview onContinue={onCreateOrders} onCancel={onResetOrders} />
			}
		>
			{/* {isMobile ? (
        <ListPreview
          templateValidatorFields={templateValidatorFields}
          previewOrders={previewOrders}
          fieldActions={fieldActions}
        />
      ) : (
        <TablePreview
          previewOrders={previewOrders}
          fields={templateValidator?.dataStructureObject?.fields}
          fieldActions={fieldActions}
        />
      )} */}
			<TablePreview
				previewOrders={previewOrders}
				fields={templateValidator?.dataStructureObject?.fields}
				fieldActions={fieldActions}
			/>
		</Drawer>
	);
};

export default ImportPreview;
