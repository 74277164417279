// Libs
import React, {
	useState,
	// useCallback
} from 'react';
// Utils
import GENERAL from 'utils/constants/general';
// import { debounce } from 'utils/libs';
import DataFieldManagerUtils from '../DataFieldManagerUtils';
// Hooks
import { useOrderZoneAssignment, useTheme } from 'hooks';
// Components
import { Space } from 'antd';
import { Menu, Dropdown, Span } from 'components';
import Read from '../Read';
import Updating from '../Updating';
import Status from '../Status';
// Styles
import styles from './ZoneId.module.css';

const { UNSUCCESS } = GENERAL.ENV.STATUS;
const { SERVICE_ID, EVENT_ID } = DataFieldManagerUtils.getFieldIds();

const Zones = ({ cities, onClick }) => (
	<Menu.Wrapper mode='inline'>
		{cities.map(c => (
			<Menu.SubMenu key={c.id} title={c.name}>
				{c.children.map(t => (
					<Menu.SubMenu key={`${c.id}.${t.id}`} title={t.name}>
						{t.children.map(z => (
							<Menu.Item
								key={`${c.id}.${t.id}.${z.id}`}
								onClick={e => {
									e.domEvent.stopPropagation();
									onClick(z);
								}}
							>
								<Span>{z.name}</Span>
							</Menu.Item>
						))}
					</Menu.SubMenu>
				))}
			</Menu.SubMenu>
		))}
	</Menu.Wrapper>
);

const Selection = ({
	field,
	rowData,
	isLoading,
	selected,
	zones,
	onGetZoneAssignment,
	onSetZoneAssignment,
	setOpen,
}) => {
	const { theme } = useTheme();
	// const fetchDebouncedZoneAssignment = useCallback(
	// 	debounce(onGetZoneAssignment, 700),
	// 	[],
	// );

	const onChange = field.onChange || onSetZoneAssignment;

	const filterData = {
		serviceId: rowData[SERVICE_ID],
		eventId: rowData[EVENT_ID],
	};

	return (
		<div className={styles.container}>
			<Dropdown.Wrapper
				trigger={['click']}
				onOpenChange={isOpen => isOpen && onGetZoneAssignment(filterData)}
				overlay={
					<Zones
						cities={zones}
						onClick={option => {
							onChange({ option, rowData, selected });
							setOpen(false);
						}}
					/>
				}
			>
				<a
					style={{ color: theme._primaryColor }}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<Space>{rowData[field.name] || 'Seleccione'}</Space>
				</a>
			</Dropdown.Wrapper>
		</div>
	);
};

const ZoneId = ({ field, rowData, selected }) => {
	const [isOpen, setOpen] = useState(false);
	const { state, onGetZoneAssignment, onSetZoneAssignment, onRetry } =
		useOrderZoneAssignment();

	if (state.status === UNSUCCESS)
		return (
			<Status
				className={styles.container}
				message={state.message}
				onRetry={onRetry}
			/>
		);

	if (state.isSetting) return <Updating className={styles.container} />;

	if (!isOpen)
		return (
			<Read
				className={styles.container}
				field={field}
				rowData={rowData}
				setOpen={setOpen}
			/>
		);

	return (
		<Selection
			field={field}
			rowData={rowData}
			isLoading={state.isLoading}
			selected={selected}
			zones={state.data}
			onGetZoneAssignment={onGetZoneAssignment}
			onSetZoneAssignment={onSetZoneAssignment}
			setOpen={setOpen}
		/>
	);
};

export default ZoneId;
