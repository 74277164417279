// Libs
import React, { useState, useCallback } from 'react';
// Utils
import GENERAL from 'utils/constants/general';
import { debounce, filterOption } from 'utils/libs';
import DataFieldManagerUtils from 'components/DataFieldManager/DataFieldManagerUtils';
import { getValueFromRowData } from 'components/DataFieldManager/OnlyReadField/OnlyReadField';
// Hooks
import { useOrderUserAssignment, useTheme } from 'hooks';
// Components
import { Wrapper, LogoIcon, Select } from 'components';
import Updating from '../Updating';
import Status from '../Status';
// Styles
import styles from './AssignedTechId.module.css';

const { SERVICE_ID, ZONE_ID, CENTRAL_ID } = DataFieldManagerUtils.getFieldIds();
const { UNSUCCESS } = GENERAL.ENV.STATUS;
const componentStyle = {
	select: {
		dark: styles.containerSelectDark,
		light: styles.containerSelectLight,
	},
	dropdown: {
		dark: styles.containerDropdownDark,
		light: styles.containerDropdownLight,
	},
};

const getUsersWithCurrentUser = (currentUser, users) => {
	const hasCurrentUser = !!users.find(u => u.id === currentUser.id);
	if (!hasCurrentUser) users.unshift(currentUser);
	return users;
};

const Selection = ({
	orderUserAssignment,
	rowData,
	selected,
	field,
	style,
}) => {
	const { themeProfile } = useTheme();
	const [isOpen, setIsOpen] = useState(false);
	const { state, onGetUserAssignment, onSetUserAssignment, onRetry } =
		orderUserAssignment;

	if (state.status === UNSUCCESS)
		return (
			<Status
				className={componentStyle[style][themeProfile]}
				message={state.message}
				onRetry={onRetry}
			/>
		);

	if (state.isSetting)
		return <Updating className={componentStyle[style][themeProfile]} />;

	const fetchDebouncedUserAssignment = useCallback(
		debounce(onGetUserAssignment, 200),
		[],
	);

	const onChange = field.onChange || onSetUserAssignment;

	const filterData = {
		serviceId: rowData[SERVICE_ID],
		zoneId:
			rowData.orderZone?.zoneId ||
			rowData.contractZone?.zoneId ||
			rowData[ZONE_ID],
		centralId: rowData.central?.id || rowData[CENTRAL_ID],
	};

	const currentUser = {
		id: rowData[field.id],
		name: getValueFromRowData({ field, rowData }),
	};
	const _users = !isOpen
		? state.data
		: getUsersWithCurrentUser(currentUser, state.data);

	return (
		<div
			onClick={e => e.stopPropagation()}
			className={componentStyle[style][themeProfile]}
		>
			<Select
				width='100%'
				showSearch
				filterOption={filterOption}
				value={!isOpen || state.isLoading ? currentUser.name : currentUser.id}
				onSearch={searchValue =>
					fetchDebouncedUserAssignment({
						...filterData,
						searchValue,
					})
				}
				onChange={(_value, { props }) =>
					_value !== currentUser.id &&
					onChange({
						field,
						rowData,
						selected,
						option: {
							id: _value,
							name: props.children,
							entityId: props.entityId,
							entityName: props.entityName,
						},
					})
				}
				onDropdownVisibleChange={visible => {
					if (visible) onGetUserAssignment(filterData);
					setIsOpen(visible);
				}}
				dropdownRender={menu => (
					<>
						{menu}
						{state.isLoading && (
							<Wrapper width='100%' justifyContent='center'>
								<LogoIcon spin={true} />
							</Wrapper>
						)}
					</>
				)}
			>
				{_users.map(user => (
					<Select.Option
						key={user.id}
						value={user.id}
						entityId={user.entityId}
						entityName={user.entityName}
					>
						{user.name}
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

const AssignedTechId = ({ field, rowData, selected, style = 'select' }) => {
	const orderUserAssignment = useOrderUserAssignment();
	const { isDarkTheme } = useTheme();
	const { onRetry } = orderUserAssignment;

	if (orderUserAssignment.state.status === UNSUCCESS)
		return (
			<Status
				className={isDarkTheme ? styles.containerDark : styles.containerLight}
				message={orderUserAssignment.state.message}
				onRetry={onRetry}
			/>
		);

	if (orderUserAssignment.state.isSetting)
		return (
			<Updating
				className={isDarkTheme ? styles.containerDark : styles.containerLight}
			/>
		);

	return (
		<Selection
			orderUserAssignment={orderUserAssignment}
			rowData={rowData}
			selected={selected}
			field={field}
			style={style}
		/>
	);
};

export default AssignedTechId;
