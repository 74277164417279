// Libs
import React from 'react';
// Components
import { TableVirtualized } from 'components';

const { Row, onRightClickRow, onClickRow } = TableVirtualized;

const RowRenderer = ({
	rowKey,
	columns,
	style,
	filteredDatasource,
	selected,
	setState,
	rowData,
}) => {
	const onFilterItems = filteredItems =>
		setState(prev => ({
			...prev,
			selected: { ...prev.selected, items: filteredItems },
		}));

	const isSelected = selected.items.findIndex(id => id === rowData.id) !== -1;

	const styleRow = {
		...style,
		color: isSelected && 'lightseagreen',
		backgroundColor: isSelected && 'rgb(44,44,54)',
	};

	return (
		<Row
			key={rowKey}
			style={styleRow}
			onClick={() =>
				onClickRow({
					id: rowData.id,
					selected,
					onClickedRow: onFilterItems,
				})
			}
			onContextMenu={e =>
				onRightClickRow(
					e,
					'id',
					selected.items,
					filteredDatasource,
					onFilterItems,
				)
			}
		>
			{columns}
		</Row>
	);
};

export default RowRenderer;
