// Libs
import React from 'react';
// Utils
import { formatDate } from 'utils/libs/dateFormats';
import DataFieldManagerUtils from '../DataFieldManagerUtils';
// Components
import { Span } from 'components';

const { DATETIME, DATE, BOOLEAN } = DataFieldManagerUtils.getFieldTypes();

export const getUIValueProp = field => {
	const levels = {
		shallow: field.uiValueProp,
		deep: field[field.uiValueProp],
	};
	return levels[field.uiValuePropLevel] || levels.shallow;
};

export const getValueFromRowData = ({ field, rowData }) => {
	return field.uiValueProp
		? rowData[getUIValueProp(field)]
		: rowData[field.name] || rowData[field.id];
};

const checkTimezone = ({ value, field, profile }) => {
	if (
		(field.type !== DATETIME && field.type !== DATE) ||
		!profile?.organization?.tz
	)
		return value;

	return formatDate(value, profile);
};

const formatBooleanValue = ({ value, field }) => {
	if (field.type !== BOOLEAN) return value;
	return value === true || value === 'true' ? 'Si' : '';
};

const OnlyReadField = ({ rowData, field, profile, emphasis }) => {
	let value = getValueFromRowData({ field, rowData });
	if (value) {
		value = checkTimezone({ value, field, profile });
		value = formatBooleanValue({ value, field });
	}
	return (
		<Span emphasis={emphasis} maxHeight='200px' overflow='auto'>
			{value}
		</Span>
	);
};

export default OnlyReadField;
