// Libs
import React from 'react';
// Utils
import { ImportUtils } from 'utils/Orders/ImportProcess';
// Components
import { CloseIcon } from 'components';
import { OnlyReadField } from 'components/DataFieldManager';

const ShowError = ({ rowData, field }) => {
	const unsuccess =
		rowData[ImportUtils.getValidationField(field)] ===
		ImportUtils.getStatus().UNSUCCESS;

	if (!unsuccess) return null;

	return (
		<div style={{ display: 'flex', marginLeft: '8px' }}>
			<CloseIcon />
		</div>
	);
};

const Read = ({ className, field, rowData, setOpen = () => {} }) => (
	<div
		className={className}
		onClick={e => {
			e.stopPropagation();
			setOpen(true);
		}}
	>
		<OnlyReadField rowData={rowData} field={field} />
		{!rowData[field.id] && <ShowError rowData={rowData} field={field} />}
	</div>
);

export default Read;
