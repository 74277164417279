// Libs
import React from 'react';
import { Card } from 'antd';
// Utils
import GENERAL from '../../../../../../utils/constants/general';
import { formatNumber, coinsUtils } from '../../../../../../utils/libs';
import { getPaymentColorState } from '../../../../../Billings/utils';
// Components
import { Button, TableVirtualized, Checkbox, Link } from 'components';
// Styles
import './InvoicePaycutCard.css';

const { ENV } = GENERAL;
const { Meta } = Card;

const { onClickRow } = TableVirtualized;

export default function InvoicePaycutCard(props) {
	const {
		id,
		name,
		production_amount,
		production_charge,
		from_date,
		to_date,
		creator_name,
		entity_name,
		created_at,
		updated_at,
		coin_id,
		state,
		state_label,
		customProps,
	} = props;
	const {
		paycutsManage,
		getPaycutsResumeModal,
		coins,
		getInvoicePays,
		getPaycutsModal,
		getPaycutResume,
		mutate1ObjectInBillings,
	} = customProps;
	const coinSymbol = coinsUtils.getDataByCoinId(coin_id, 'symbol', coins);
	const productionAmount = formatNumber.new(production_amount, coinSymbol, 2);
	const productionCharge = formatNumber.new(production_charge, coinSymbol, 2);
	const difference = formatNumber.new(
		production_amount - production_charge,
		coinSymbol,
		2,
	);

	const onClickedRow = filteredItems =>
		mutate1ObjectInBillings('paycuts', {
			selected: { ...paycutsManage.selected, items: filteredItems },
		});

	return (
		<div>
			<div
				className='invoice_paycut-flag-payment-state'
				style={getPaymentColorState(state)}
			/>
			<Meta
				id='paycut_meta-card-container'
				title={
					<div className='invoice_paycut-container-title-card'>
						<span className='invoice_paycut-card-document'>
							<Checkbox
								margin='0 5px 0 0'
								checked={paycutsManage.selected.items.indexOf(id) !== -1}
								onChange={() =>
									onClickRow({
										id,
										selected: paycutsManage.selected,
										onClickedRow: onClickedRow,
									})
								}
							/>
							{id}-{name}
						</span>

						<div style={{ height: '18px' }}>
							<span className='invoice_paycut-body-concept-item'>
								Creado por:
							</span>
							<span className='invoice_paycut-card-creator'>
								{creator_name} - {entity_name}
							</span>
						</div>
						<div style={{ height: '18px' }}>
							<span className='invoice_paycut-body-concept-item'>Fecha:</span>
							<span className='invoice_paycut-card-created_at'>
								{created_at}
							</span>
						</div>
						<div style={{ height: '18px' }}>
							<span className='invoice_paycut-body-concept-item'>
								Actualizado:
							</span>
							<span className='invoice_paycut-card-created_at'>
								{updated_at}
							</span>
						</div>
					</div>
				}
				description={
					<div>
						<div className='invoice_paycut-container-data-input'>
							<div className='invoice_paycut-data'>
								<div className='invoice_paycut-body-container-item'>
									<div className='invoice_paycut-body-concept-item'>
										Cobrado: <b>{productionAmount}</b>
									</div>
								</div>
								<div className='invoice_paycut-body-container-item'>
									<div className='invoice_paycut-body-concept-item'>
										Cobrar: <b>{productionCharge}</b>
									</div>
								</div>
								<div className='invoice_paycut-body-container-item'>
									<div className='invoice_paycut-body-concept-item'>
										Diferencia: <b>{difference}</b>
									</div>
								</div>
							</div>

							<div className='invoice_paycut-data'>
								<div className='invoice_paycut-body-container-item'>
									<div className='invoice_paycut-body-concept-item'>
										Desde: <b>{from_date}</b>
									</div>
								</div>
								<div className='invoice_paycut-body-container-item'>
									<div className='invoice_paycut-body-concept-item'>
										Hasta: <b>{to_date}</b>
									</div>
								</div>
								<div className='invoice_paycut-body-container-item'>
									<div className='invoice_paycut-body-concept-item'>
										Estado: <b>{state_label}</b>
									</div>
								</div>
							</div>
						</div>

						{paycutsManage.selected.items.length === 0 && (
							<div className='invoice_paycut-buttons-container'>
								<Link
									to={ENV.ROUTES.PATHS.BILLINGS_INVOICES_PAYS_MOBILE}
									onClick={() => {
										mutate1ObjectInBillings('paycuts', {
											selected: { ...paycutsManage.selected, items: [id] },
										});
										getInvoicePays([id]);
									}}
								>
									<Button style={{ height: '28px' }}>Ver Pagos</Button>
								</Link>

								<Button
									height='28px'
									margin='0 0 0 10px'
									color='white'
									onClick={() => {
										mutate1ObjectInBillings('paycuts', {
											selected: { ...paycutsManage.selected, items: [id] },
										});
										mutate1ObjectInBillings('signPaycutModal', {
											isOpen: true,
										});
										getPaycutResume({
											...getPaycutsResumeModal,
											isInvoice: getPaycutsModal.type === 'invoice',
											paycutIds: [id],
										});
									}}
								>
									Resumen
								</Button>
							</div>
						)}
					</div>
				}
			/>
		</div>
	);
}
