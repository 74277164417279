// Libs
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Immutable from 'immutable';
// Selectors
import { selectProfile } from 'screens/Login/selectors';
// Hooks
import { useToastMessage } from 'hooks';
// Components
import { Wrapper } from 'components';
import { DefaultForm } from 'core/FormBuilder';
import ImportPreview from './ImportPreview';
import ImportButton from './ImportButton';
import DateFormat from './DateFormat';

const initialState = {
	dateFormat: undefined,
	preview: undefined,
	orders: [],
	filters: {},
};

function ImportOrders({
	isMobile,
	form,
	values,
	setValues,
	submitForm,
	getProcessedComponentSqlData,
}) {
	const sendToast = useToastMessage();

	const profile = useSelector(selectProfile);

	const [state, setState] = useState(initialState);
	const { templateId: validationTemplateId, templates = [] } = values;

	const templateValidator = templates.find(
		template => template.id === validationTemplateId,
	);

	const onSetState = props => setState(prev => ({ ...prev, ...props }));
	const onResetOrders = () =>
		setState(prev => ({ ...initialState, dateFormat: prev.dateFormat }));

	const _orders = Immutable.List(state.orders).toJS();

	return state.preview ? (
		<ImportPreview
			isMobile={isMobile}
			orders={_orders}
			filters={state.filters}
			dateFormat={state.dateFormat}
			templateValidator={templateValidator}
			onSetState={onSetState}
			onResetOrders={onResetOrders}
			sendToast={sendToast}
		/>
	) : (
		<Wrapper padding='0' flexDirection='column' width='270px'>
			<DefaultForm
				isMobile={isMobile}
				externalDisabled={_orders.length > 0}
				form={form}
				values={values}
				setValues={setValues}
				submitForm={submitForm}
				getProcessedComponentSqlData={getProcessedComponentSqlData}
			/>
			<DateFormat
				dateFormat={state.dateFormat}
				disabled={!validationTemplateId || !!_orders.length}
				onChange={value => onSetState({ dateFormat: value })}
			/>
			<ImportButton
				disabled={!state.dateFormat}
				dateFormat={state.dateFormat}
				templateValidator={templateValidator}
				profile={profile}
				formComponentValues={values}
				onSetState={onSetState}
				onResetOrders={onResetOrders}
				sendToast={sendToast}
			/>
		</Wrapper>
	);
}

export default ImportOrders;
